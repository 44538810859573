<template>
  <div>
    <div class="Teacher-checkWork">
      <div class="stu-module-header">
        <div class="stu-module-title">在线作业批阅</div>
      </div>
      <div class="content" v-if="queryRoot">
        <el-form :model="form" inline>
          <el-form-item label="">
            <el-input placeholder="教师名称/工号" v-model="form.info">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="query"
              ></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="学期">
            <el-select v-model="form.term" clearable>
              <el-option
                v-for="item in semesterOptions"
                :key="item"
                :label="`第${item}学期`"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select v-model="form.gradeId" clearable @change="gradeChange">
              <el-option
                v-for="(item, index) in gradeOptions"
                :key="index"
                :label="item.gradeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-table :data="tableData" :height="450" v-loading="loading">
          <el-table-column align="center" label="序号" width="60">
            <template slot-scope="scope">
              <span>{{ scope.$index + (page - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="workName"
            align="center"
            sortable
            label="作业名称"
          ></el-table-column>
          <el-table-column
            prop="courseCode"
            align="center"
            sortable
            label="课程编号"
          ></el-table-column>
          <el-table-column
            prop="courseName"
            align="center"
            sortable
            label="课程名称"
          ></el-table-column>
          <el-table-column
            prop="gradeName"
            align="center"
            sortable
            label="年级"
          ></el-table-column>
          <el-table-column
            prop="currentTerm"
            align="center"
            sortable
            label="学期"
          ></el-table-column>
          <el-table-column
            prop="sumCon"
            align="center"
            label="总记录数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="ysumCon"
            align="center"
            label="已批阅数"
            sortable
          ></el-table-column>
          <el-table-column
            prop="nsumCon"
            align="center"
            sortable
            label="待批阅数"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="80"
            v-if="checkRoot"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="onChecked(scope.row)"
                >批阅</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <no-root v-else></no-root>
    </div>
  </div>
</template>

<script>
import { QueryEduCourseWorkSetReview } from "@/libs/api/teacher/check";
import { EduGrade, QueryEduTermByEduGradeId } from "@/libs/api/teacher/select";
export default {
  name: "teacher-checkWork",
  data() {
    return {
      loading: false,
      form: {
        info: null,
        term: "",
        gradeId: "",
      },
      gradeOptions: [],
      semesterOptions: [],
      tableData: [],
      page: 1,
      pageSize: 10,
      total: 0,

      checkData: [],
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherCheckWork:list");
    },
    checkRoot() {
      return this.$sysPerKey("eduTeacherCheckWork:check");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.selectInit();
        this.query();
      }
    },
  },
  mounted() {
    if (this.queryRoot) {
      this.selectInit();
      this.query();
    }
  },
  methods: {
    selectInit() {
      let data = new FormData();
      EduGrade(data).then((res) => {
        this.gradeOptions = res.data;
      });
    },
    gradeChange(val) {
      this.form.term = "";
      if (val) {
        this.semesterOptions = [];
        QueryEduTermByEduGradeId({ eduGradeId: val }).then((res) => {
          if (res.data) {
            const { currentTerm, maxTerm } = res.data;
            for (let i = 0; i < maxTerm; i++) {
              this.semesterOptions.push(i + 1);
            }
          }
        });
      } else {
        this.semesterOptions = [];
      }
    },
    handleCurrentChange(val) {
      if (val) {
        this.page = val;
        this.query();
      }
    },
    handleSizeChange(val) {
      if (val) {
        this.pageSize = val;
        this.query();
      }
    },
    query() {
      this.loading = true;
      const p = {
        page: this.page,
        pageSize: this.pageSize,
        ...this.form,
      };
      QueryEduCourseWorkSetReview(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChecked(row) {
      if (row) {
        this.$router.push({
          path: "checkWork/list",
          query: {
            workSetId: row.id,
            courseId: row.courseId,
          },
        });
      }
    },
  },
};
</script>

<style scope lang="less">
.Teacher-checkWork {
  background: #fff;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
  }
}
</style>